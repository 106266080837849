import { Widgets } from "./Widgets";

export class SMSSender extends Widgets {
    constructor(selector) {
        super(selector);
        this.close_btn = this.element.querySelector(".header .close-btn");
        this.send_btn = this.element.querySelector(".footer .send-btn");
        this.input = this.element.querySelector(".footer input[name='phone']");
        this.sms_sent = this.element.querySelector(".footer .sms-sent");
        this.error_message = this.element.querySelector(".footer .error-message");
    }
    init() {
        let event = new Event("SMSSenderInitiated");
        event.widget = this;
        this.parent.element.dispatchEvent(event);

        this.close_btn.addEventListener("click", e => {
            this.removeModal();
        });
        this.send_btn.addEventListener("click", e=> {
            const phone = $(this.input).cleanVal();
            if (phone.length<11) {
                const error = this.parent.translate("widget/share_topic/messages/cellphone_incomplete");
                this.error_message.innerHTML = error;
                return;
            }
            const params = {
                to: phone,
                url: this.parent.currentLocation(this.parent.router.root)
            }
            this.send(params);
        });
        this.input.addEventListener("keyup", e => {
            this.error_message.innerHTML = "";
        });        
    }
    removeModal() {
        this.element.remove();
        let event = new Event("SMSSenderClosed");
        event.widget = this;
        this.element.dispatchEvent(event);        
    }
    async send(params) {
        this.parent.loading.start();
        this.send_btn.classList.add("disabled");
        let data = {
            "to": params.to,
            "name": params?.user,
            "link": this.parent.settings.environment.live + params.url
        }

        let sendsms = await fetch(this.parent.settings.smssender.endpoint, {
            method: "POST",
            // mode: "no-cors", // no-cors, *cors, same-origin
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data), 
        });
        let response = await sendsms.json();
        try {
            // window.alert(this.parent.translate("widget/share_topic/messages/sms_sent"));
            this.parent.loading.stop();
            this.send_btn.remove();
            this.sms_sent.classList.add("active");
        } catch (error) {
            console.log(error);
        }
        var _that = this;
        setTimeout(()=>{
            _that.removeModal();
        }, 3000)
        return response;
    }
}