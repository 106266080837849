import { Widgets } from "./Widgets";

export class Buscador extends Widgets {
    constructor() {
        super("qelp-search");
        this.input = document.querySelector("input[id='search']");
        this.suggestions = document.querySelector(".qclatam-suggestions-list");
        this.completions;
        this.loading = false;
        this.input?.addEventListener("keyup", e=> {
            switch (e.key) {
                case "ArrowUp":
                    this.nextSuggetionItem("ArrowUp");
                    break;
                case "ArrowDown":
                    this.nextSuggetionItem("ArrowDown");
                    break;
                case "Escape":
                    this.suggestions.classList.remove("active");
                        break;
                case "Enter":
                    const suggestion = this.suggestions.querySelector("li.active");
                    if (this.suggestions.classList.contains("active") && suggestion) {
                        this.updateInputField(e);
                    } else {
                        terms = encodeURI(this.input.value);
                        url = eHelpFramework.getRoot(eHelpFramework.getRoute("search_results")) +`/${terms}`;
                        window.location.href = url;                    
                        break;
                    }            
                default:
                    this.checkCompletions(e);
                    break;
            }
        })
    }
    nextSuggetionItem(direction) {
        var current = this.suggestions.querySelector("li.active");
        if (!current) {
            next = this.suggestions.querySelector("li:first-child");
            next.classList.add("active");
        } else {
            current.classList.remove("active");
            const next = direction=="ArrowDown" ? current.nextElementSibling : current.previousElementSibling
            next.classList.add("active");
        }
    }
    updateInputField(e) {
        var suggestion;
        console.log(e)
        const words = this.input.value.split(" ");
        const last_word = words.pop();

        if (e.type=="click") {
            suggestion = e.currentTarget;
        } else {
            suggestion = this.suggestions.querySelector("li.active");
        }
        
        words.push(suggestion.dataset.term)

        this.input.value = words.join(" ") + " ";
        this.suggestions.classList.remove("active");
        this.input.focus();        
    }
    async checkCompletions(e) {
        const terms = encodeURI(this.input.value);
        if (terms.length<1) return;
        if (this.loading) return;
        this.loading=true;
        const response = await this.parent.postData("autocomplete", {
            term: terms
        });
        this.completions = response.results.map(term => ({ term }))
        this.loading=false;

        console.log(this.completions);
        

        const options = {
            includeScore: true,
            keys: ['term'],
            threshold: 0.7,
            distance: 10
        }
        const fuse = new Fuse(this.completions, options)
        const words = this.input.value.split(" ");
        const last_word = words.pop();


        

        const result = fuse.search(last_word);

        var limiter = 10
        if (result.length>0) {
            const suggestions = document.createElement("ul");
            for (const item of result) {
                const li = document.createElement("li");
                li.classList.add("mdn-Text","mdn-Text--bod","mdn-Text--md")
                li.dataset.term = item.item.term;
                li.innerHTML = '<em class="mdn-Icon-lupa mdn-Icon--md "></em>'+item.item.term;
                suggestions.append(li);
                limiter--;
                if (limiter==0) break;
                li.addEventListener("click", e=> {
                    this.updateInputField(e);
                });
            }
            this.suggestions.innerHTML = "";
            this.suggestions.append(suggestions);
            this.suggestions.classList.add("active");
        } else {
            this.suggestions.classList.remove("active");
        }
    }
}
