var Handlebars = require("../../../../CSR_QelpCare_Projects/csr-claro-br/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"usecase-step-wrapper step-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":26,"column":50},"end":{"line":26,"column":60}}}) : helper)))
    + "\" data-step=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":26,"column":73},"end":{"line":26,"column":83}}}) : helper)))
    + "\"\">\n                <div class=\"usecase-step step-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":27,"column":46},"end":{"line":27,"column":56}}}) : helper)))
    + "\" data-step=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":27,"column":69},"end":{"line":27,"column":79}}}) : helper)))
    + "\" animation=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"animation") || (depth0 != null ? lookupProperty(depth0,"animation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"animation","hash":{},"data":data,"loc":{"start":{"line":27,"column":92},"end":{"line":27,"column":105}}}) : helper)))
    + "\">\n <div class=\"widget usecase-step-image usecase-step-image-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":28,"column":58},"end":{"line":28,"column":68}}}) : helper)))
    + "\">\n\n                        <!-- Wrapped Start Screen and Device Image-->\n"
    + ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0),true,{"name":"ifEquals","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":72,"column":37}}})) != null ? stack1 : "")
    + "                        <!-- Wrapped Start Screen and Device Image-->\n\n                    </div>\n\n                    <div class=\"content\">\n                        <div class=\"content-wrapper\">\n                            <div class=\"step-number\">\n                                "
    + alias4((lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,(data && lookupProperty(data,"index")),"+",1,{"name":"math","hash":{},"data":data,"loc":{"start":{"line":80,"column":32},"end":{"line":80,"column":53}}}))
    + "\n                            </div>\n                            <div class=\"step-content\">\n                                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":83,"column":32},"end":{"line":83,"column":42}}}) : helper))) != null ? stack1 : "")
    + "\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"image\" \n                            data-hardware-image="
    + alias4(((helper = (helper = lookupProperty(helpers,"hardwareImage") || (depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hardwareImage","hash":{},"data":data,"loc":{"start":{"line":33,"column":48},"end":{"line":33,"column":65}}}) : helper)))
    + " \n                            data-has-wrapper=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"hasWrapper") : stack1), depth0))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"coordinates") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":28},"end":{"line":38,"column":35}}})) != null ? stack1 : "")
    + "                            data-screen-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                            data-screen-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                            data-image-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                            data-image-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                            data-screen-position-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                            data-screen-position-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                            data-offset-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                            data-offset-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\">\n                            <div class=\"image-wrapper\">\n                                <img class=\"hardware\" src=\""
    + alias4(alias5(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageURL") : stack1), depth0))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":48,"column":100},"end":{"line":48,"column":108}}}) : helper)))
    + "\">\n                                <img class=\"startScreen\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imageURL") || (depth0 != null ? lookupProperty(depth0,"imageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageURL","hash":{},"data":data,"loc":{"start":{"line":49,"column":62},"end":{"line":49,"column":74}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":49,"column":81},"end":{"line":49,"column":89}}}) : helper)))
    + "\">\n                            </div>\n                        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            data-coordinates-x=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"coordinates") : depth0)) != null ? lookupProperty(stack1,"left") : stack1), depth0))
    + "\"\n                            data-coordinates-y=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"coordinates") : depth0)) != null ? lookupProperty(stack1,"top") : stack1), depth0))
    + "\"\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"image\" \n                            data-hardware-image="
    + alias4(((helper = (helper = lookupProperty(helpers,"hardwareImage") || (depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hardwareImage","hash":{},"data":data,"loc":{"start":{"line":54,"column":48},"end":{"line":54,"column":65}}}) : helper)))
    + " \n                            data-has-wrapper=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"hasWrapper") : stack1), depth0))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"coordinates") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":28},"end":{"line":59,"column":35}}})) != null ? stack1 : "")
    + "                            data-screen-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                            data-screen-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                            data-image-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                            data-image-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                            data-screen-position-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                            data-screen-position-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                            data-offset-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                            data-offset-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\">\n                            <div class=\"image-wrapper\">\n                                <img class=\"startScreen\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imageURL") || (depth0 != null ? lookupProperty(depth0,"imageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageURL","hash":{},"data":data,"loc":{"start":{"line":69,"column":62},"end":{"line":69,"column":74}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":69,"column":81},"end":{"line":69,"column":89}}}) : helper)))
    + "\">\n                            </div>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Usecase Template -->\n<div class=\"page usecase "
    + alias4(((helper = (helper = lookupProperty(helpers,"css_class") || (depth0 != null ? lookupProperty(depth0,"css_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"css_class","hash":{},"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":38}}}) : helper)))
    + "\">\n    <div class=\"wrapper\">\n        <div class=\"breadcrumbs-and-share\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n            <button class=\"primary widget copy-link\">\n                <i class=\"material-icons-outlined file_copy\">\n                    file_copy\n                </i>\n                <span class=\"material-icons-outlined check_circle\">\n                    check_circle\n                </span>\n                "
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/copy-link",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":60}}}))
    + "\n            </button>\n            <button class=\"primary widget share-topic\">\n                <i class=\"material-icons-outlined\">share</i>\n                <span>"
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/usecase/send",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":17,"column":22},"end":{"line":17,"column":55}}}))
    + "</span>\n            </button>\n        </div>\n        <h1 class=\"page-title\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"usecase") : depth0)) != null ? lookupProperty(stack1,"topicName") : stack1), depth0))
    + "</h1>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"steps_navigation") || (depth0 != null ? lookupProperty(depth0,"steps_navigation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"steps_navigation","hash":{},"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":30}}}) : helper))) != null ? stack1 : "")
    + "\n\n        <div class=\"widget topic-usecase\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"usecase") : depth0)) != null ? lookupProperty(stack1,"steps") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":89,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>";
},"useData":true,"useDepths":true});